import {ref} from "vue"
import {createFetch} from "@vueuse/core"

export const CSRF_TOKEN = ref("") // a mutable global variable?  sure, why not.

export const useFetcher = createFetch({
  options: {
    beforeFetch({options}: any) {
      options.headers["X-CSRF-TOKEN"] = CSRF_TOKEN.value
      return {options}
    },
  },
})
