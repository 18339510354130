<style lang="scss" scoped>
table {
	width: 100%;
	th {
		vertical-align: middle;
	}
}
</style>

<template>
	<div>
		<table>
			<thead>
				<tr>
					<datatable-header
						v-for="(head_column, i) in normalizedColumns"
						:key="i"
						:column="head_column"
						:settings="settings"
						:direction="getSortDirectionForColumn(head_column)"
						@change="setSortDirectionForColumn"
					></datatable-header>
				</tr>
			</thead>
			<tbody>
				<slot v-for="(row, i) in filteredRows" :row="row" :columns="normalizedColumns">
					<tr :key="i">
						<datatable-cell
							v-for="(column, j) in normalizedColumns"
							:key="j"
							:column="column"
							:row="row"
						></datatable-cell>
					</tr>
				</slot>
				<tr v-if="filteredRows.length == 0">
					<td :colspan="normalizedColumns.length">
						<slot name="no-results">
							There is nothing here.
						</slot>
					</td>
				</tr>
			</tbody>
      <tfoot v-if="footer">
        <slot name="footer">
          <tr>
              <datatable-footer
                v-for="(column, i) in normalizedColumns"
                :key="i"
                :column="column"
                :rows="filteredRows"
              ></datatable-footer>
          </tr>
        </slot>
      </tfoot>
		</table>
	</div>
</template>

<script>
import { toJsonPretty } from "@/common/utils/index";

import datatableHeader from './Header.vue'
import datatableCell from './Cell.vue'
import datatableFooter from './Footer.vue'

export default {
	components: {
		datatableHeader,
		datatableCell,
    datatableFooter,
	},
	props: {
		columns: {
			type: Array,
			required: true,
		},
		data: {
			type: Array,
			required: true
		},
		filterBy: {
			type: String,
			required: false,
			default: ''
		},
    footer: {
		  type: Boolean,
      required: false,
      default: false,
    },
    defaultSort: {
		  type: Object,
      required: false,
      default: null
    }
	},
	data() {
		return {
			settings: {
				table: {
					class: 'table table-hover table-striped',
					row: {
						classes: ['']
					},
					sorting: {
						classes: {
							canSort: ['sort'],
							sortNone: ['bi', 'bi-list'],
              sortAsc: ['bi', 'bi-sort-alpha-down'],
							sortDesc: ['bi', 'bi-sort-alpha-up-alt'],
						}
					}
				},
				pager: {
					classes: {
						pager: 'pagination',
						li: '',
						a: '',
						selected: 'active',
						disabled: 'disabled'
					},
					icons: {
						previous: '&lt;',
						next: '&gt;',
					}
				},
			},
			sort: this.defaultSort
		}
	},
	methods: {
		getSortDirectionForColumn(column_definition) {
			if(this.sort && this.sort.by == column_definition)
			{
				return this.sort.direction;
			}
		},
		setSortDirectionForColumn(direction, column){
			this.sort = {
				by: column,
				direction: direction
			};
		},
	},
	computed: {
		filteredRows() {
			let data = this.data;
			let search = this.filterBy;

			if(search && search.length>0) {
				// this is filtering on *all* data -  should it instead only filter on things visible?
				data = data.filter((row) => {
					return Object.keys(row).some((key) => {
						return String(row[key]).toLowerCase().indexOf(search.toLowerCase()) > -1;
					})
				})
			}

			if(this.sort)
			{
				let sortColumn = this.sort.by;
				let order = (this.sort.direction =='asc') ? 1 : -1;
				let field = sortColumn.field;

				if(sortColumn.sortable && field)
				{
					let columnType = sortColumn.type || 'string';

					data = data.slice().sort((a, b) => {
						a = String(a[field]).toLowerCase();
						b = String(b[field]).toLowerCase();

						if (columnType === 'date') {
							return (a === b ? 0 : new Date(a).getTime() > new Date(b).getTime() ? 1 : -1) * order;
						} else if (columnType === 'number') {
							return (+a === +b ? 0 : +a > +b ? 1 : -1) * order;
						} else {
							return (a === b ? 0 : a > b ? 1 : -1) * order;
						}
					});
				}
			}

			return data;
		},
		normalizedColumns() {
			return this.columns.map((column) => {
				var col = column;

				if(column.sortable===undefined) {
					if(column.field===undefined)
					{
						column.sortable = false;
					} else {
						column.sortable = true;
					}
				}
				if(column.align === undefined) {
					column.align = 'center';
				}

				return col;
			});
		},
		paginatedUsers() {
			return this.paginate(this.filteredUsers, this.length, this.pagination.currentPage);
		}
	},
};

/*
	columns:
		label: Label to display
		component: component to put in this column (row)
		headerComponent: component for the headeri
		field: field on the data
		sortable: true/false (defaults to true)
		headerClass
		align: // text-align, defaults to center
		interpolate : // bind as content is html

*/
</script>
