// Language utils, unabashedly English-focused

export function pluralize(n: number, singular: string, plural?: string): string {
  const pluralized = plural ?? `${singular}s`
  return n === 1 ? singular : pluralized
}

export function countAndPluralize(n: number, singular: string, plural?: string): string {
  return `${n} ${pluralize(n, singular, plural)}`
}
