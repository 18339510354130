<template>
	<th :style="{'text-align': column.align}" :class="column.footerClass">
    <component v-if="column.footerComponent" :is="column.footerComponent" :column="column" :rows="rows"></component>
	</th>
</template>

<script>
export default {
	props: {
		column: [Object, Array],
		rows: Array,
	},
	methods: {
	},
}
</script>
